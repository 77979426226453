/* 
Superio | Job Portal & Job Board React NextJS Template

Template Name: Superio
Author: ib-themes
Version: 1.4.0


/*** 

====================================================================
Fonts
====================================================================

***/
@import "./icons.scss";
@import "./flaticon.scss";
@import "./fontawesome-all.scss";
@import "./line-awesome.scss";
@import "./custom-slick.scss";
// @import "node_modules/react-modal-video/scss/modal-video.scss";

/*** 

====================================================================
Reset
====================================================================

***/

/*** 

====================================================================
Global Settings
====================================================================

***/

@import "./base/base.scss";
@import "./base/forms.scss";
@import "./base/typography.scss";
@import "./base/utilities.scss";

@import "./components/about.scss";
@import "./components/accordion.scss";
@import "./components/ads.scss";
@import "./components/banner-box.scss";
@import "./components/button.scss";
@import "./components/Callout.scss";
@import "./components/clients.scss";
@import "./components/companies.scss";
@import "./components/contact.scss";
@import "./components/dashboard.scss";
@import "./components/feature.scss";
@import "./components/footer.scss";
@import "./components/fun-fact.scss";
@import "./components/header.scss";
@import "./components/job.scss";
@import "./components/list.scss";
@import "./components/login.scss";
@import "./components/map.scss";
@import "./components/menu.scss";

@import "./components/message-box.scss";
@import "./components/page-title.scss";
@import "./components/pie-graph.scss";
@import "./components/portfolio.scss";
@import "./components/preloader.scss";
@import "./components/progress-bar.scss";
@import "./components/registr-banner.scss";
@import "./components/resume.scss";
@import "./components/section-title.scss";
@import "./components/select.scss";
@import "./components/sidebar.scss";
@import "./components/social.scss";
@import "./components/socialIcons.scss";
@import "./components/table.scss";
@import "./components/tabs.scss";
@import "./components/testimonials.scss";
@import "./components/tooltip.scss";
@import "./components/uploadings.scss";

@import "./components/sections/app.scss";
@import "./components/sections/browse.scss";
@import "./components/sections/candidates.scss";
@import "./components/sections/cta.scss";
@import "./components/sections/faq.scss";
@import "./components/sections/invoice.scss";
@import "./components/sections/jobseeker.scss";
@import "./components/sections/ls-section.scss";
@import "./components/sections/news.scss";
@import "./components/sections/pricing.scss";
@import "./components/sections/process.scss";
@import "./components/sections/recruiter.scss";
@import "./components/sections/steps.scss";
@import "./components/sections/subscribe.scss";
@import "./components/sections/tnc.scss";
@import "./components/job-search.scss";

@import "./layout/blog-sidebar.scss";
@import "./layout/blog-single.scss";
@import "./layout/shop/cart.scss";
@import "./layout/shop/order.scss";
@import "./layout/shop/products.scss";
@import "./layout/shop/single.scss";
@import "./layout/shop/tabs.scss";


@import "./components/shame.scss";
@import "./job-description-reset.scss";

/*** 
====================================================================
59. Responsive
====================================================================
***/
@import "./base/responsive.scss";
@import "./input-range-slider";
@import "./custom-nextjs.scss";


.work-block.-type-2 {
    .icon-wrap {
        margin: 0 auto;
        width: 111px;
        height: 111px;
        border-radius: 100%;
        font-size: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.-blue {
        background: rgba(#beedff, 0.4);

        .icon::before {
            color: #87deff;
        }
        }

        &.-red {
        background: rgba(#fecbd1, 0.4);

        .icon::before {
            color: #ffa3ad;
        }
        }

        &.-yellow {
        background: rgba(#fddf8e, 0.4);

        .icon::before {
            color: #ffd257;
        }
        }
    }

    h5 {
        margin-top: 29px;
    }

    p {
        margin-top: 13px;
    }
}


.divider {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 20px;
    color: #696969;
    background: #ffffff;
    margin: 10px 0 25px;
    span {
        position: relative;
        z-index: 9;
    }

    &:before {
        position: absolute;
        left: 0;
        top: 10px;
        height: 1px;
        width: 100%;
        background: #ecedf2;
        border-radius: 8px;
        content: "";
      }
      
      &:after {
        position: absolute;
        left: 50%;
        top: 0px;
        height: 20px;
        width: 60px;
        background: #fff;
        margin-left: -30px;
        content: "";
      }
}

.w-percent-100 {
    width: 100%;
}

#root { height: 100% }

.job-title-letter {
    margin-top:1px;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &.style-0 {
        background-color: #34A853;
    }
    
    &.style-1 {
        background-color: #F9AB00;
    }

    &.style-2 {
        background-color: #D93025;
    }

    &.style-3 {
        background-color: #202124;
    }

    &.style-4 {
        background-color: #4947D0;
    }
}

.flex-align-self-center {
    align-self: center;
}
