.Resume {
    max-width: 1024px;
    width: calc(90vw - 48px);
    min-height: 80vh;
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #727b72;
    padding: 15px 25px 25px;
    margin: 25px;
    h2 {
        margin-top: 50px;
    }
    .buttonAdd {
        margin-top: 15px;
    }
}

fieldset {
    padding: 15px;
    margin: 15px 0;
    legend {
        background-color: #AC8CE3;
        color: #FFF;
        padding: 5px 15px;
    }
    border: 1px dashed #AC8CE3;
    Button {
        margin-left: 15px;
        max-height: 35px;
    }
    .list-item {
        background-color: red;
    }
}