/*
  	Flaticon icon font: Flaticon
  	Creation date: 27/12/2020 17:38
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../assets/fonts/Flaticon.eot");
  src: url("../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Flaticon.woff2") format("woff2"),
    url("../assets/fonts/Flaticon.woff") format("woff"), url("../assets/fonts/Flaticon.ttf") format("truetype"),
    url("../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-notebook:before {
  content: "\f100";
}
.flaticon-edit:before {
  content: "\f101";
}
.flaticon-placeholder:before {
  content: "\f102";
}
.flaticon-paper-plane:before {
  content: "\f103";
}
.flaticon-user:before {
  content: "\f104";
}
.flaticon-unlink:before {
  content: "\f105";
}
.flaticon-search:before {
  content: "\f106";
}
.flaticon-drop:before {
  content: "\f107";
}
.flaticon-christmas-tree:before {
  content: "\f108";
}
.flaticon-plus:before {
  content: "\f109";
}
.flaticon-battery:before {
  content: "\f10a";
}
.flaticon-target:before {
  content: "\f10b";
}
.flaticon-first-aid-kit:before {
  content: "\f10c";
}
.flaticon-tablet:before {
  content: "\f10d";
}
.flaticon-dustbin:before {
  content: "\f10e";
}
.flaticon-telegram-logo:before {
  content: "\f10f";
}
.flaticon-4-square-shapes:before {
  content: "\f110";
}
.flaticon-speech-bubble-and-three-dots:before {
  content: "\f111";
}
.flaticon-man:before {
  content: "\f112";
}
.flaticon-tree-of-love:before {
  content: "\f113";
}
.flaticon-play-button:before {
  content: "\f114";
}
.flaticon-royal-crown-of-elegant-vintage-design:before {
  content: "\f115";
}
.flaticon-desktop-computer-with-magnifying-lens-focusing-on-data:before {
  content: "\f116";
}
.flaticon-test-tube-flask-and-drop-of-blood:before {
  content: "\f117";
}
.flaticon-stocks-graphic-on-laptop-monitor:before {
  content: "\f118";
}
.flaticon-attachment:before {
  content: "\f119";
}
.flaticon-transport:before {
  content: "\f11a";
}
.flaticon-recycle-triangle-of-three-arrows-outlines:before {
  content: "\f11b";
}
.flaticon-arrows:before {
  content: "\f11c";
}
.flaticon-snowflake:before {
  content: "\f11d";
}
.flaticon-clock:before {
  content: "\f11e";
}
.flaticon-find-in-folder:before {
  content: "\f11f";
}
.flaticon-smartphone:before {
  content: "\f120";
}
.flaticon-arrow-pointing-to-right:before {
  content: "\f121";
}
.flaticon-gas-pump:before {
  content: "\f122";
}
.flaticon-house-silhouette:before {
  content: "\f123";
}
.flaticon-arrows-1:before {
  content: "\f124";
}
.flaticon-floor:before {
  content: "\f125";
}
.flaticon-exercise:before {
  content: "\f126";
}
.flaticon-love-planet:before {
  content: "\f127";
}
.flaticon-workers:before {
  content: "\f128";
}
.flaticon-open-magazine:before {
  content: "\f129";
}
.flaticon-confirm-schedule:before {
  content: "\f12a";
}
.flaticon-star:before {
  content: "\f12b";
}
.flaticon-order:before {
  content: "\f12c";
}
.flaticon-key:before {
  content: "\f12d";
}
.flaticon-medical:before {
  content: "\f12e";
}
.flaticon-smartphone-1:before {
  content: "\f12f";
}
.flaticon-plug:before {
  content: "\f130";
}
.flaticon-arrows-2:before {
  content: "\f131";
}
.flaticon-arrows-3:before {
  content: "\f132";
}
.flaticon-money-bag:before {
  content: "\f133";
}
.flaticon-next:before {
  content: "\f134";
}
.flaticon-back:before {
  content: "\f135";
}
.flaticon-reload:before {
  content: "\f136";
}
.flaticon-headphones:before {
  content: "\f137";
}
.flaticon-signs:before {
  content: "\f138";
}
.flaticon-diamond:before {
  content: "\f139";
}
.flaticon-chat:before {
  content: "\f13a";
}
.flaticon-phone:before {
  content: "\f13b";
}
.flaticon-down-chevron:before {
  content: "\f13c";
}
.flaticon-up-chevron:before {
  content: "\f13d";
}
.flaticon-stairs:before {
  content: "\f13e";
}
.flaticon-music-player:before {
  content: "\f13f";
}
.flaticon-play-button-1:before {
  content: "\f140";
}
.flaticon-speaker:before {
  content: "\f141";
}
.flaticon-menu:before {
  content: "\f142";
}
.flaticon-share:before {
  content: "\f143";
}
.flaticon-map:before {
  content: "\f144";
}
.flaticon-hourglass:before {
  content: "\f145";
}
.flaticon-layers:before {
  content: "\f146";
}
.flaticon-home:before {
  content: "\f147";
}
.flaticon-handshake:before {
  content: "\f148";
}
.flaticon-time:before {
  content: "\f149";
}
.flaticon-worldwide:before {
  content: "\f14a";
}
.flaticon-board:before {
  content: "\f14b";
}
.flaticon-twitter:before {
  content: "\f14c";
}
.flaticon-smartphone-2:before {
  content: "\f14d";
}
.flaticon-mobile:before {
  content: "\f14e";
}
.flaticon-maps-and-flags:before {
  content: "\f14f";
}
.flaticon-add:before {
  content: "\f150";
}
.flaticon-substract:before {
  content: "\f151";
}
.flaticon-phone-call:before {
  content: "\f152";
}
.flaticon-monitor:before {
  content: "\f153";
}
.flaticon-menu-1:before {
  content: "\f154";
}
.flaticon-cancel:before {
  content: "\f155";
}
.flaticon-24-hours:before {
  content: "\f156";
}
.flaticon-t-shirt-outline:before {
  content: "\f157";
}
.flaticon-folder-outline:before {
  content: "\f158";
}
.flaticon-quote:before {
  content: "\f159";
}
.flaticon-play-button-2:before {
  content: "\f15a";
}
.flaticon-right-quotation-sign:before {
  content: "\f15b";
}
.flaticon-up-arrow:before {
  content: "\f15c";
}
.flaticon-open-mail-interface-symbol:before {
  content: "\f15d";
}
.flaticon-menu-button:before {
  content: "\f15e";
}
.flaticon-smartphone-3:before {
  content: "\f15f";
}
.flaticon-add-1:before {
  content: "\f160";
}
.flaticon-play-button-3:before {
  content: "\f161";
}
.flaticon-play-button-4:before {
  content: "\f162";
}
.flaticon-support:before {
  content: "\f163";
}
.flaticon-close:before {
  content: "\f164";
}
.flaticon-heart-1:before {
  content: "\f165";
}
.flaticon-renewable:before {
  content: "\f166";
}
.flaticon-bar-chart:before {
  content: "\f167";
}
.flaticon-profit:before {
  content: "\f168";
}
.flaticon-settings:before {
  content: "\f169";
}
.flaticon-confirm:before {
  content: "\f16a";
}
.flaticon-edit-1:before {
  content: "\f16b";
}
.flaticon-share-1:before {
  content: "\f16c";
}
.flaticon-decreasing:before {
  content: "\f16d";
}
.flaticon-graph:before {
  content: "\f16e";
}
.flaticon-multi-tab:before {
  content: "\f16f";
}
.flaticon-graph-1:before {
  content: "\f170";
}
.flaticon-graph-2:before {
  content: "\f171";
}
.flaticon-tick:before {
  content: "\f172";
}
.flaticon-pin:before {
  content: "\f173";
}
.flaticon-alarm-clock:before {
  content: "\f174";
}
.flaticon-close-1:before {
  content: "\f175";
}
.flaticon-settings-1:before {
  content: "\f176";
}
.flaticon-telephone:before {
  content: "\f177";
}
.flaticon-home-1:before {
  content: "\f178";
}
.flaticon-search-1:before {
  content: "\f179";
}
.flaticon-notification:before {
  content: "\f17a";
}
.flaticon-attachment-1:before {
  content: "\f17b";
}
.flaticon-down-arrow:before {
  content: "\f17c";
}
.flaticon-tv:before {
  content: "\f17d";
}
.flaticon-phone-receiver:before {
  content: "\f17e";
}
.flaticon-next-1:before {
  content: "\f17f";
}
.flaticon-back-1:before {
  content: "\f180";
}
.flaticon-clock-1:before {
  content: "\f181";
}
.flaticon-email:before {
  content: "\f182";
}
.flaticon-email-1:before {
  content: "\f183";
}
.flaticon-engineer:before {
  content: "\f184";
}
.flaticon-cog:before {
  content: "\f185";
}
.flaticon-cog-1:before {
  content: "\f186";
}
.flaticon-call:before {
  content: "\f187";
}
.flaticon-call-1:before {
  content: "\f188";
}
.flaticon-email-2:before {
  content: "\f189";
}
.flaticon-shopping-bag:before {
  content: "\f18a";
}
.flaticon-clock-2:before {
  content: "\f18b";
}
.flaticon-custom:before {
  content: "\f18c";
}
.flaticon-settings-2:before {
  content: "\f18d";
}
.flaticon-monitor-1:before {
  content: "\f18e";
}
.flaticon-stats:before {
  content: "\f18f";
}
.flaticon-monitor-2:before {
  content: "\f190";
}
.flaticon-bars:before {
  content: "\f191";
}
.flaticon-bar-chart-1:before {
  content: "\f192";
}
.flaticon-bars-1:before {
  content: "\f193";
}
.flaticon-camera:before {
  content: "\f194";
}
.flaticon-camera-1:before {
  content: "\f195";
}
.flaticon-camera-2:before {
  content: "\f196";
}
.flaticon-dslr-camera:before {
  content: "\f197";
}
.flaticon-tower:before {
  content: "\f198";
}
.flaticon-technical-support:before {
  content: "\f199";
}
.flaticon-maintenance:before {
  content: "\f19a";
}
.flaticon-production:before {
  content: "\f19b";
}
.flaticon-3d-printer:before {
  content: "\f19c";
}
.flaticon-gift:before {
  content: "\f19d";
}
.flaticon-winner:before {
  content: "\f19e";
}
.flaticon-diploma:before {
  content: "\f19f";
}
.flaticon-diploma-1:before {
  content: "\f1a0";
}
.flaticon-medal:before {
  content: "\f1a1";
}
.flaticon-title:before {
  content: "\f1a2";
}
.flaticon-certificate:before {
  content: "\f1a3";
}
.flaticon-meeting-point:before {
  content: "\f1a4";
}
.flaticon-pin-1:before {
  content: "\f1a5";
}
.flaticon-email-3:before {
  content: "\f1a6";
}
.flaticon-call-2:before {
  content: "\f1a7";
}
.flaticon-telephone-1:before {
  content: "\f1a8";
}
.flaticon-teacher:before {
  content: "\f1a9";
}
.flaticon-support-2:before {
  content: "\f1aa";
}
.flaticon-design-tool:before {
  content: "\f1ab";
}
.flaticon-success:before {
  content: "\f1ac";
}
.flaticon-startup:before {
  content: "\f1ad";
}
.flaticon-creativity:before {
  content: "\f1ae";
}
.flaticon-creativity-1:before {
  content: "\f1af";
}
.flaticon-puzzle:before {
  content: "\f1b0";
}
.flaticon-vector-1:before {
  content: "\f1b1";
}
.flaticon-brainstorm:before {
  content: "\f1b2";
}
.flaticon-pencil:before {
  content: "\f1b3";
}
.flaticon-typography:before {
  content: "\f1b4";
}
.flaticon-lightbulb:before {
  content: "\f1b5";
}
.flaticon-cancel-1:before {
  content: "\f1b6";
}
.flaticon-dislike:before {
  content: "\f1b7";
}
.flaticon-paper-plane-1:before {
  content: "\f1b8";
}
.flaticon-mail:before {
  content: "\f1b9";
}
.flaticon-call-3:before {
  content: "\f1ba";
}
.flaticon-shopping-cart:before {
  content: "\f1bb";
}
.flaticon-shopping-cart-1:before {
  content: "\f1bc";
}
.flaticon-heart:before {
  content: "\f1bd";
}
.flaticon-compare:before {
  content: "\f1be";
}
.flaticon-user-1:before {
  content: "\f1bf";
}
.flaticon-loupe:before {
  content: "\f1c0";
}
.flaticon-paper-clip:before {
  content: "\f1c1";
}
.flaticon-shield:before {
  content: "\f1c2";
}
.flaticon-diamond-2:before {
  content: "\f1c3";
}
.flaticon-checked:before {
  content: "\f1c4";
}
.flaticon-right:before {
  content: "\f1c5";
}
.flaticon-left:before {
  content: "\f1c6";
}
.flaticon-padlock:before {
  content: "\f1c7";
}
.flaticon-starred:before {
  content: "\f1c8";
}
.flaticon-star-1:before {
  content: "\f1c9";
}
.flaticon-grid:before {
  content: "\f1ca";
}
.flaticon-option:before {
  content: "\f1cb";
}
.flaticon-sort:before {
  content: "\f1cc";
}
.flaticon-delete:before {
  content: "\f1cd";
}
.flaticon-envelope:before {
  content: "\f1ce";
}
.flaticon-quote-1:before {
  content: "\f1cf";
}
.flaticon-briefcase:before {
  content: "\f1d0";
}
.flaticon-file:before {
  content: "\f1d1";
}
.flaticon-money-1:before {
  content: "\f1d2";
}
.flaticon-promotion:before {
  content: "\f1d3";
}
.flaticon-megaphone:before {
  content: "\f1d4";
}
.flaticon-vector:before {
  content: "\f1d5";
}
.flaticon-web-programming:before {
  content: "\f1d6";
}
.flaticon-rocket-ship:before {
  content: "\f1d7";
}
.flaticon-headhunting:before {
  content: "\f1d8";
}
.flaticon-approved:before {
  content: "\f1d9";
}
.flaticon-support-1:before {
  content: "\f1da";
}
.flaticon-first-aid-kit-1:before {
  content: "\f1db";
}
.flaticon-car:before {
  content: "\f1dc";
}
.flaticon-compact:before {
  content: "\f1dd";
}
.flaticon-map-locator:before {
  content: "\f1de";
}
.flaticon-clock-3:before {
  content: "\f1df";
}
.flaticon-money:before {
  content: "\f1e0";
}
.flaticon-money-2:before {
  content: "\f1e1";
}
.flaticon-bookmark:before {
  content: "\f1e2";
}
.flaticon-search-2:before {
  content: "\f1e3";
}
.flaticon-search-3:before {
  content: "\f1e4";
}
