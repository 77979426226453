/*==========================
Default Tabs
===========================*/

.default-tabs {
  position: relative;
  overflow: hidden;
}

.default-tabs .tab-buttons {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
}

.default-tabs .tab-buttons li {
  position: relative;
  font-size: 15px;
  line-height: 20px;
  color: #202124;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  line-height: 20px;
  padding: 5px 20px;
  background: #f0f5f7;
  border: 1px solid #f0f5f7;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 7px 14px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.default-tabs .tab-buttons li.active-btn {
  background-color: transparent;
  border: 1px solid #ecedf2;
  color: #202124;
}

.default-tabs .tabs-content {
  position: relative;
  width: 100%;
  padding: 25px 0;
}

.default-tabs .tabs-content p {
  margin-bottom: 24px;
}

.default-tabs .tabs-content p:last-child {
  margin-bottom: 0;
}

/* Style Two */

.default-tabs.style-two .tab-buttons {
  background: #ffffff;
  border-bottom: 1px solid #e7e7ec;
}

.default-tabs.style-two .tab-buttons li {
  padding: 9px 10px;
  border-radius: 0;
  border: 0;
  font-size: 16px;
  color: #696969;
  border-bottom: 3px solid transparent;
  background: none;
  margin: 0;
  margin-right: 40px;
}

.default-tabs.style-two .tab-buttons li.active-btn {
  color: var(--primary-color);
  border-bottom: 3px solid #5c6770;
}
