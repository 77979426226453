.LoginForm {
    margin-top: 50px;
    button {
        margin: 0 15px;
        min-width: 200px;
    }
    .TextField {
        min-width: 500px !important;
        margin-bottom: 35px;
    }
   
}

