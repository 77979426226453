/*** 

====================================================================
Call To Action
====================================================================

***/

.call-to-action{
  position: relative;
}

.call-to-action .outer-box{
  position: relative;
  background-color: rgba(25,103,210,.07);
  padding: 50px 60px 10px;
  border-radius: 10px;
  overflow: hidden;
}

.call-to-action .sec-title{
  margin-bottom: 0;
}

.call-to-action .image-column{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 360px;
  background-repeat: no-repeat;
  background-position: -10px 20px ;
  background-size: cover;
}

.call-to-action .image-column .image{
  display: none;
  margin-bottom: 0;
}

.call-to-action .content-column{
  position: relative;
  margin-right: 50px;
}

.call-to-action .sec-title{
  position: relative;
  margin-bottom: 50px;
}

.call-to-action .sec-title .theme-btn{
  margin-top: 30px;
}

.call-to-action.style-two{
  padding-bottom: 100px;
}

/*** 

====================================================================
Call To Action Two
====================================================================

***/

.call-to-action-two{
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 170px 0 160px;
  text-align: center;
}

.call-to-action-two .sec-title{
  margin-bottom: 33px;
}

.call-to-action-two .btn-box{
  position: relative;
  display: inline-block;
}

.call-to-action-two .theme-btn{
  margin: 0 10px 10px;
}

.call-to-action-two .btn-style-two{
  color: #ffffff;
}

.call-to-action-two.style-two{
  position: relative;
}

.call-to-action-two.style-two:before{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color);
  content: "";
  opacity: .85;
}

/*** 

====================================================================
Call To Action Three
====================================================================

***/

.call-to-action-three{
  position: relative;
  padding: 60px 0;
  background-color: #DEEBFF;
}

.call-to-action-three .outer-box{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.call-to-action-three .sec-title{
  margin-bottom: 0;
  margin-right: 30px;
}

.call-to-action-three.style-two{
  background: var(--primary-color);
}

.call-to-action-three.style-two .sec-title .text a{
  color: #ffffff;
}

.call-to-action-three.style-two .theme-btn{
  min-width: 180px;
}

/***

==================================================================
Call To Action Four
==================================================================

***/

.call-to-action-four{
  position: relative;
  background-color: rgba(25,103,210,.07);
  padding: 30px 30px;
  padding-right: 140px;
  border-radius: 10px;
  overflow: hidden;
}

.call-to-action-four h5{
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 17px;
}

.call-to-action-four p{
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  margin-bottom: 25px;
}

.call-to-action-four .image{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 140px;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}

.call-to-action-four.style-two{
  padding: 50px 50px;    
}

.call-to-action-four.style-two h5{
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  color: #202124;
}

.call-to-action-four.style-two p{
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 25px;
}

.call-to-action-four.style-two .image{
  width: 350px;
}