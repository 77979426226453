.ResumeStatus {
    margin: 30px;
    
    .ResumeStatusItemWrapper {
        margin-bottom: 15px;
        & > div {
            display: flex;
            align-items: center;
            font-size: 0.75rem;       
            svg {
                font-size: 0.85rem !important;
    
            }
            .label {
                margin-left: 5px;
            }
        }
    }
}