@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~bootstrap/scss/bootstrap";
@import "./scss/main.scss";

body {
  &.red {
    background-color: red;
  }
  .text-error {
    color: #d32f2f;
  }
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-area {
  background-image: url('./assets/images/shape1.png');
  background-repeat: no-repeat;
  height: 994px;
  width: 100%;
  position: relative;
  z-index: 1;
  &::before {
    content: '';
    background-image: url('./assets/images/shape3.png');
    background-position: center left;
    z-index: -1;
  }
  &:after {
    content: '';
    background-image: url('./assets/images/shape2.png');
    background-position: center right;
    background-size: 70% 100%;
    width: 100vw;
    height: 100vh;
    // z-index: 234234;
    display: inline-block;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -3;
    opacity: 0.5;
  }
}
fieldset {
  .list-item {
    padding: 15px 30px;
    box-shadow: 1px 2px 7px 0px rgba(156, 156, 156, 0.51);
    -webkit-box-shadow: 1px 2px 7px 0px rgba(156, 156, 156, 0.51);
    -moz-box-shadow: 1px 2px 7px 0px rgba(156, 156, 156, 0.51);
    border-radius: 5px;
  }
}
.text-capitalize {
  text-transform: capitalize;
}

.general-details {
  max-width: 1024px;
  width: calc(90vw - 48px);
  min-height: 80vh;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #727b72;
  padding: 15px 25px 25px;
  margin: 25px;
}

.mask-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(50, 50, 50, 0.71);
  color: #fff;
  font-size: 26px;
  z-index: 10000;
  left: 0;
  top: 0;
  .fa {
    font-size: 70px;
    animation: rotate 2s linear infinite; /* Rotate the icon infinitely with a duration of 2 seconds */

  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg); /* Start the rotation from 0 degrees */
  }
  100% {
    transform: rotate(360deg); /* Rotate the icon 360 degrees */
  }
}




.label-inline {
  min-width: 100px;
  display: inline-block;
}
.missing-value {
  font-size: 0.75rem;
  font-style: italic;
}

.mui-selected-empty {
  opacity: 0.5;
}
.api-autocomplete-wrapper {
  background: rgba(25, 103, 210, 0.07);
  display: flex;
  align-items: center;
  border-radius: 5px;

  .MuiAutocomplete-root {
    flex: 1;
    .MuiOutlinedInput-notchedOutline {
      background: none!important;
    } 
  }
  .icon {
    padding-left: 10px;
    font-size: 25px;
    color: rgba(25, 103, 210, 1);
  }
}

.skill-table-autocomplete {
  .fa-times {
    font-size: 0.75em !important;
    color: #1967D2!important;
    padding: 0 5px;
  }
  .MuiChip-root {
    background: rgba(25, 103, 210, 0.3);
    color: #1967D2;
    border-radius: 20px;
  }
}

.skill-table-row{
    border-bottom: 2px solid #eee;
    margin-bottom: 15px;
    padding: 25px 0;
    .skill {
      font-weight: 700;
    }
    .proficiency-dropdown-option {
      color: #000;
    }

}

.MuiDialogTitle-root {
  width: 100%;
  text-align: center;
  font-weight: 700 !important;
  font-size: 1.6rem !important;
  margin-bottom: 30px !important;
  margin-top: 15px !important;
}